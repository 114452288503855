export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Site Genel',
    route: null,
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Site Menü',
        route: 'SiteMenus',
        resource: 'ADMIN_SITE_MENU',
        action: 'read',
      },
      {
        title: 'Carousel',
        route: 'Carousel',
        resource: 'ADMIN_CAROUSEL',
        action: 'read',
      },
      {
        title: 'Sabit İçerikler',
        route: 'Contents',
        resource: 'ADMIN_CONTENTS',
        action: 'read',
      },
      {
        title: 'Pop-Up Görsel',
        route: 'Popup',
        resource: 'ADMIN_POPUP_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kurumsal',
    route: null,
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Tarihçe',
        route: 'History',
        resource: 'ADMIN_HISTORY_LIST',
        action: 'read',
      },
      {
        title: 'Hakkımızda',
        route: 'About',
        resource: 'ADMIN_ABOUT',
        action: 'read',
      },
      {
        title: 'Bizden Haberler',
        route: 'News',
        resource: 'ADMIN_NEWS',
        action: 'read',
      },
      {
        title: 'Basında Biz',
        route: 'Press',
        resource: 'ADMIN_PRESS_LIST',
        action: 'read',
      },
      {
        title: 'Fuarlar',
        route: 'Fairs',
        resource: 'ADMIN_FAIRS_LIST',
        action: 'read',
      },
      {
        title: 'Sertifikalar',
        route: 'Certificates',
        resource: 'ADMIN_CERTIFICATES_LIST',
        action: 'read',
      },
      {
        title: 'Bayilikler / Markalar',
        route: 'Dealers',
        resource: 'ADMIN_DEALERS_LIST',
        action: 'read',
      },
      {
        title: 'İletişim',
        route: 'Contacts',
        resource: 'ADMIN_CONTACT',
        action: 'read',
      },
      {
        title: 'Referanslarımız',
        route: 'References',
        resource: 'ADMIN_REFERENCES_LIST',
        action: 'read',
      },
      {
        title: 'Whatsapp',
        route: 'Whatsapp',
        resource: 'ADMIN_WHATSAPP',
        action: 'read',
      },
    ],
  },
  {
    title: 'Blog',
    route: 'Blog',
    icon: 'BookIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'BlogAdd',
        resource: 'ADMIN_BLOG_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Blog',
        resource: 'ADMIN_BLOG_LIST',
        action: 'read',
      },
      {
        title: 'Kategoriler',
        route: 'BlogCategories',
        resource: 'ADMIN_BLOG_CATEGORIES_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'İnsan Kaynakları',
    route: null,
    icon: 'UsersIcon',
    children: [
      {
        title: 'Açık Pozisyonlar',
        route: 'HrPositions',
        resource: 'ADMIN_HR_POSITIONS_LIST',
        action: 'read',
      },
      {
        title: 'Formlar',
        route: 'HrForms',
        resource: 'ADMIN_HR_FORMS_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Galeri',
    route: null,
    icon: 'CameraIcon',
    children: [
      {
        title: 'Fotoğraf Galerisi',
        route: 'ImageGallery',
        resource: 'ADMIN_PHOTO_GALLERY',
        action: 'read',
      },
      {
        title: 'Video Galerisi',
        route: 'VideoGallery',
        resource: 'ADMIN_VIDEO_GALLERY',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ürünler',
    route: null,
    icon: 'BoxIcon',
    children: [
      {
        title: 'Ürün Listesi',
        route: 'Products',
        resource: 'ADMIN_PRODUCTS',
        action: 'read',
      },
      {
        title: 'Ürün Grupları',
        route: 'ProductGroups',
        resource: 'ADMIN_PRODUCTS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Hizmetler',
    route: 'Services',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Oluştur',
        route: 'ServiceAdd',
        resource: 'ADMIN_SERVICE_ADD',
        action: 'read',
      },
      {
        title: 'Listele',
        route: 'Services',
        resource: 'ADMIN_SERVICE_LIST',
        action: 'read',
      },
      {
        title: 'Kategoriler',
        route: 'ServiceCategories',
        resource: 'ADMIN_SERVICE_CATEGORIES_LIST',
        action: 'read',
      },
    ],
  },
  {
    title: 'Modüller',
    route: null,
    icon: 'CodesandboxIcon',
    children: [
      {
        title: 'Sosyal Medya',
        route: 'Socialmedia',
        resource: 'ADMIN_MODULES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Dil Çeviri',
    route: null,
    icon: 'FlagIcon',
    children: [
      {
        title: 'Çeviri Listesi',
        route: 'Translate',
        resource: 'ADMIN_TRANSLATE',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    route: null,
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Para Birimleri',
        route: 'Currencies',
        resource: 'ADMIN_DEFINES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    route: 'Users',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    route: null,
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Genel Yapılandırma',
        route: 'Configs',
        resource: 'ADMIN_CONFIG',
        action: 'read',
      },
      {
        title: 'SEO Yapılandırma',
        route: 'Components',
        resource: 'ADMIN_CONFIG',
        action: 'read',
      },
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP',
        action: 'read',
      },
    ],
  },
]
